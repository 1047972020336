import {AUTH_TYPE} from '@Libraries/login-signup-library';
import {switchToConfirmLogin} from '@Components/login-page/login-page-reducer';
import type {ConfirmLoginState} from '@Components/login-page/login-page.types';
import {useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import {useAppDispatch} from '@/hooks';
import {useUpdateAuthFormTypes} from '@/hooks/login-signup/useUpdateAuthFormTypes';
import {useUpdateErrorStates} from '@/hooks/login-signup/useUpdateErrorStates';

export const useLoginError = () => {
  const authType = useLoginPageAuthType();
  const dispatch = useAppDispatch();

  const {showErrors, hideErrors} = useUpdateErrorStates();
  const {updateAuthTypes} = useUpdateAuthFormTypes();

  const onLoginError = (error: string, onAuthTypeChangeCallback: Function, email = '', password = '') => {
    let errorMessage = '';
    let section = authType;

    switch (error) {
      case 'user-blocked':
        errorMessage = window.i18next.t('pmwjs_user_account_blocked');
        break;
      case 'googleLinked':
        errorMessage = window.i18next.t('pmwjs_email_exists_google', {supportLink: window.PMW.util.site_url('user/feedbackform'), className: 'content-danger'});
        break;
      case 'fbLinked':
        errorMessage = window.i18next.t('pmwjs_email_exists_fb', {supportLink: window.PMW.util.site_url('user/feedbackform'), className: 'content-danger'});
        break;
      case 'appleLinked':
        errorMessage = window.i18next.t('pmwjs_email_exists_apple', {supportLink: window.PMW.util.site_url('user/feedbackform'), className: 'content-danger'});
        break;
      case 'googleFbLinked':
        errorMessage = window.i18next.t('pmwjs_email_exists_google_fb', {supportLink: window.PMW.util.site_url('user/feedbackform'), className: 'content-danger'});
        break;
      case 'low-recaptcha-v3-score':
        section = AUTH_TYPE.CONFIRM_LOGIN;
        hideErrors();
        const confirmLoginData: ConfirmLoginState = {
          confirmLoginType: AUTH_TYPE.LOGIN,
          encryptedEmail: email,
          encryptedPassword: password,
        };
        dispatch(switchToConfirmLogin(confirmLoginData));
        break;

      case 'low-recaptcha-v2-score':
        errorMessage = window.i18next.t('pmwjs_robot_detected_error');
        updateAuthTypes(AUTH_TYPE.LOGIN);
        section = AUTH_TYPE.LOGIN;
        break;

      default:
        errorMessage = window.i18next.t('pmwjs_invalid_credentials');
    }

    if (authType === AUTH_TYPE.CONFIRM_LOGIN) {
      section = AUTH_TYPE.LOGIN;
      updateAuthTypes(AUTH_TYPE.LOGIN);
    }

    if (authType === section) {
      showErrors(errorMessage);
    } else {
      onAuthTypeChangeCallback(errorMessage);
    }
  };

  return {onLoginError};
};
