import React, {useState} from 'react';
import {getGrecaptchaToken} from '@Libraries/recaptcha-library';
import {
  AUTH_MODE,
  EMAIL_PLACEHOLDER,
  getCommonAuthFormParams,
  getoAuthFormParams,
  getRecaptchaSelectorValue,
  handleAuthFormSubmit,
  LOGIN_OPTIONS,
  PASSWORD_PLACEHOLDER,
} from '@Libraries/login-signup-library';
import {executeThunk} from '@Utils/thunk.util';
import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import type {AsyncLoginParams, AuthFormSubmissionParams, BaseAuthFormProps, OauthLoginParams, WebLoginParams} from '@Components/login-page/login-page.types';
import {AsyncLoginSuccessResponse, AuthFormSubmissionType} from '@Components/login-page/login-page.types';
import {useLoginPageAuthButtonsDisabled, useLoginPageAuthMode, useLoginPageLoginActionType} from '@Components/login-page/login-page.hooks';
import {isValidEmail} from '@Utils/string.util';
import {INPUT_FIELD_TYPE} from '../input-field';
import {Text} from '../text';
import {Type} from '../button';
import {AuthInput} from '../auth-input';
import styles from './login-form.module.scss';
import {AuthFormSubmitButton} from '../auth-form-submit-button';
import {useAppDispatch} from '@/hooks';
import {useLoginError} from '@/hooks/login-signup/useLoginError';
import {openTwoFactorAuthenticationVerificationModal} from '@Modals/two-factor-authentication-verification-modal/two-factor-authentication-verification-modal.library';
import {noop} from '@Utils/general.util';
import useProcessAsyncLoginSuccessResponse from '@Hooks/login-signup/useProcessAsyncLoginSuccessResponse';

interface LoginFormProps extends BaseAuthFormProps {
  switchToForgotPassword?: VoidFunction;
  loginOptionsMode?: LOGIN_OPTIONS;
  onPanelUpdate?: VoidFunction;
}

const LOGIN_RECAPTCHA_SELECTOR = 'js-g-recaptcha-response-login';

export function LoginForm({
  switchToForgotPassword = $.noop,
  showSmallButton = false,
  loginOptionsMode = LOGIN_OPTIONS.DEFAULT,
  onAuthFormSubmitSuccess = $.noop,
  onPanelUpdate = $.noop,
}: LoginFormProps) {
  const {onLoginError} = useLoginError();
  const disableLoginButton = useLoginPageAuthButtonsDisabled();
  const authMode = useLoginPageAuthMode();
  const loginActionType = useLoginPageLoginActionType();
  const {processAsyncLoginSuccessResponse} = useProcessAsyncLoginSuccessResponse();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const isLoginEmailInvalid = (): boolean => {
    return !isValidEmail(email);
  };

  const isLoginPasswordInvalid = (): boolean => {
    return password.length === 0;
  };

  const onSubmit = () => {
    const isEmailInvalid = isLoginEmailInvalid();
    const isPasswordInvalid = isLoginPasswordInvalid();

    setIsEmailInvalid(isEmailInvalid);
    setIsPasswordInvalid(isPasswordInvalid);

    if (isEmailInvalid || isPasswordInvalid) {
      return;
    }

    if (window.PMW.grecaptchaReady) {
      getGrecaptchaToken(handleAuthFormSubmit.bind(null, onLoginSubmit), LOGIN_RECAPTCHA_SELECTOR);
    }
  };

  const handleEmailInputChange = React.useCallback((txt: string) => {
    setEmail(txt.trim());
  }, []);

  const handlePasswordInputChange = React.useCallback((txt: string) => {
    setPassword(txt.trim());
  }, []);

  const onLoginSubmit = async () => {
    switch (authMode) {
      case AUTH_MODE.ASYNC:
        await onAsyncLoginSubmit();
        break;
      case AUTH_MODE.DEFAULT:
        await onWebLoginSubmit();
        break;
    }
  };

  const onAsyncLoginSuccess = (response: AsyncLoginSuccessResponse): void => {
    processAsyncLoginSuccessResponse(response, onAuthFormSubmitSuccess);
  };

  const onAsyncLoginError = (response: any): void => {
    onLoginError(response.payload.data.reason, onPanelUpdate, email, password);
  };

  const onWebLoginSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.WEBLOGIN,
      requestParams: getWebLoginParams(),
    };

    if (loginOptionsMode === LOGIN_OPTIONS.OAUTH) {
      authFormSubmissionParams.type = AuthFormSubmissionType.OAUTHLOGIN;
      authFormSubmissionParams.requestParams = getOauthLoginParams();
    }
    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onWebLoginSuccess,
      noop
    );
  };

  const onWebLoginSuccess = (response: any): void => {
    if ('pendingMFA' in response && response.pendingMFA) {
      openTwoFactorAuthenticationVerificationModal({});
    }
  };

  const onAsyncLoginSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.ASYNCLOGIN,
      requestParams: getAsyncLoginParams(),
    };
    await executeThunk(
      () => {
        return window.PMW.redux.store.dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onAsyncLoginSuccess,
      onAsyncLoginError
    );
  };

  const getWebLoginParams = () => {
    const webLoginParams: WebLoginParams = {
      email,
      password,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
      studentlogin: 0,
      ...getCommonAuthFormParams(),
    };

    return webLoginParams;
  };

  const getOauthLoginParams = () => {
    const oauthLoginParams: OauthLoginParams = {
      email,
      password,
      ...getoAuthFormParams(),
    };
    return oauthLoginParams;
  };

  const getAsyncLoginParams = () => {
    const asyncLoginParams: AsyncLoginParams = {
      email,
      password,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
      isRecaptchaV2Confirmation: 0,
    };
    return asyncLoginParams;
  };

  const onEmailFocusOut = (): void => {
    setIsEmailInvalid(isLoginEmailInvalid());
  };

  const onPasswordFocusOut = (): void => {
    setIsPasswordInvalid(isLoginPasswordInvalid());
  };

  return (
    <>
      <input type="hidden" name="gRecaptchaResponse" className={`g-recaptcha-response ${LOGIN_RECAPTCHA_SELECTOR}`} data-recaptcha-action={loginActionType} />

      <AuthInput
        label={window.i18next.t('pmwjs_email')}
        placeholder={EMAIL_PLACEHOLDER}
        type={INPUT_FIELD_TYPE.EMAIL}
        handleInput={handleEmailInputChange}
        showError={isEmailInvalid}
        onInputSubmit={onSubmit}
        errorText={window.i18next.t('pmwjs_valid_email_address')}
        onFocusOutCallback={onEmailFocusOut}
      />

      <div className={styles.passwordContainer}>
        <AuthInput
          label={window.i18next.t('pmwjs_password')}
          type={INPUT_FIELD_TYPE.PASSWORD}
          placeholder={PASSWORD_PLACEHOLDER}
          onFocusOutCallback={onPasswordFocusOut}
          handleInput={handlePasswordInputChange}
          showError={isPasswordInvalid}
          onInputSubmit={onSubmit}
          errorText={window.i18next.t('pmwjs_please_enter_a_valid_password')}
        />
      </div>

      <div className={styles.forgotPassword} onClick={switchToForgotPassword}>
        <Text val={window.i18next.t('pmwjs_login_forgot_password')} className={`${styles.text} ${styles.link} hover-transition-all`} />
      </div>

      <AuthFormSubmitButton
        btnText={window.i18next.t('pmwjs_log_in')}
        type={Type.PRIMARY}
        showSmallButton={showSmallButton}
        onClick={onSubmit}
        customClasses="-fullwidth"
        isDisabled={disableLoginButton}
        icon="icon-sign-in"
      />
    </>
  );
}
