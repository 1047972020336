import type {ReactElement} from 'react';
import React from 'react';
import {SocialMediaIcon, SocialMediaIconType} from '@Components/social-media-icon';
import styles from './social-media-icons-container.module.scss';

export function SocialMediaIconsContainer(): ReactElement {
  return (
    <div className={`${styles.socialMediaIcons} ${styles.strokeIcons} _borderless `}>
      <SocialMediaIcon type={SocialMediaIconType.FACEBOOK} />
      <SocialMediaIcon type={SocialMediaIconType.INSTAGRAM} />
      <SocialMediaIcon type={SocialMediaIconType.TWITTER} />
      <SocialMediaIcon type={SocialMediaIconType.YOUTUBE} />
    </div>
  );
}
