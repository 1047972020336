import React from 'react';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {Text, TextSize} from '../text';
import {Button, Size, Type} from '../button';
import styles from './auth-form-footer-cta.module.scss';

/**
 Renders a row with text and a CTA to be used in Auth forms
 */
interface AuthFormFooterCtaProps {
  text: string;
  btnText: string;
  onClick: VoidFunction;
  icon?: string;
}

export function AuthFormFooterCta({text, btnText, onClick, icon = ''}: AuthFormFooterCtaProps) {
  return (
    <div className={`flex-row-align-center -fullwidth ${styles.formFooter}`}>
      <Text val={text} className={`content-body ${styles.text}`} size={TextSize.XSMALL} />
      <div className={`flexbox flex-content-end ${styles.btnContainer}`}>
        <Button
          text={btnText}
          size={Size.SMALL}
          type={Type.SECONDARY}
          customClasses={`flex-row-justify-center flex-items-center flex-1 border-s-darker ${styles.btn}`}
          textClasses={`body-xs-bold ${styles.btnText}`}
          icon={icon}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
