import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {initRecaptchaV2, onRecaptchaV2Load, resetGrecaptcha} from '@Libraries/recaptcha-library';
import {AUTH_MODE, AUTH_TYPE, getCommonAuthFormParams} from '@Libraries/login-signup-library';
import {AuthFormSubmitButton} from '@Components/auth-form-submit-button';
import type {
  AsyncConfirmLoginParams,
  AsyncLoginFailResponse,
  AsyncLoginSuccessResponse,
  AuthFormSubmissionParams,
  BaseAuthFormProps,
  ConfirmLoginWebParams,
  VoidFunction,
  WebLoginSuccessResponse,
} from '@Components/login-page/login-page.types';
import {AuthFormSubmissionType} from '@Components/login-page/login-page.types';
import {executeThunk} from '@Utils/thunk.util';
import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import {useLoginPageAuthMode, useLoginPageConfirmLoginType} from '@Components/login-page/login-page.hooks';
import {disableLoadingState, enableLoadingState} from '@Components/login-page/login-page-reducer';
import {openTwoFactorAuthenticationVerificationModal} from '@Modals/two-factor-authentication-verification-modal/two-factor-authentication-verification-modal.library';
import {noop} from '@Utils/general.util';
import useProcessAsyncLoginSuccessResponse from '@Hooks/login-signup/useProcessAsyncLoginSuccessResponse';
import {useLoginError} from '@/hooks/login-signup/useLoginError';
import {useAppDispatch, useAppSelector} from '@/hooks';
import {Type} from '../button';
import styles from './confirm-login-form.module.scss';

enum ConfirmLoginType {
  DEFAULT = 0,
  STUDENT_LOGIN = 1,
}

interface ConfirmLoginProps extends BaseAuthFormProps {
  onPanelUpdate?: VoidFunction;
}

const IS_RECAPTCHA_V2_CONFIRMATION = '1';

export function ConfirmLoginForm({showSmallButton = false, onAuthFormSubmitSuccess = noop, onPanelUpdate = noop}: ConfirmLoginProps): ReactElement {
  const [disableConfirmLoginButton, setDisableConfirmLoginButton] = useState(true);
  const [token, setToken] = useState('');

  const {onLoginError} = useLoginError();
  const encryptedEmail = useAppSelector((state) => {
    return state.loginPage.encryptedEmail;
  });
  const encryptedPassword = useAppSelector((state) => {
    return state.loginPage.encryptedPassword;
  });
  const projectName = useAppSelector((state) => {
    return state.loginPage.projectName;
  });
  const authMode = useLoginPageAuthMode();
  const confirmLoginType = useLoginPageConfirmLoginType();
  const dispatch = useAppDispatch();
  const {processAsyncLoginSuccessResponse} = useProcessAsyncLoginSuccessResponse();

  useEffect(() => {
    initRecaptchaV2(onRecaptchaLoad);
  }, []);

  const onRecaptchaSuccess = (recaptchaToken: string): void => {
    setDisableConfirmLoginButton(false);
    setToken(recaptchaToken);
    dispatch(disableLoadingState());
  };

  const onRecaptchaError = (): void => {
    setDisableConfirmLoginButton(true);
    dispatch(enableLoadingState());
  };

  const onRecaptchaLoad = (): void => {
    onRecaptchaV2Load(onRecaptchaSuccess, onRecaptchaError);
  };

  const onSubmit = (): void => {
    void onConfirmLoginSubmit();
  };

  const getConfirmLoginWebParams = (): ConfirmLoginWebParams => {
    let studentLogin = ConfirmLoginType.DEFAULT;
    if (confirmLoginType === AUTH_TYPE.STUDENT_LOGIN) {
      studentLogin = ConfirmLoginType.STUDENT_LOGIN;
    }

    return {
      loginEmail: encryptedEmail,
      loginPassword: encryptedPassword,
      project: projectName,
      studentlogin: studentLogin,
      gRecaptchaResponseV2: token,
      isRecaptchaV2Confirmation: IS_RECAPTCHA_V2_CONFIRMATION,
      ...getCommonAuthFormParams(),
    };
  };

  const getAsyncConfirmLoginParams = (): AsyncConfirmLoginParams => {
    return {
      email: encryptedEmail,
      password: encryptedPassword,
      gRecaptchaResponse: token,
      isRecaptchaV2Confirmation: IS_RECAPTCHA_V2_CONFIRMATION,
    };
  };

  const onConfirmLoginSubmit = async (): Promise<void> => {
    switch (authMode) {
      case AUTH_MODE.ASYNC:
        await onAsyncConfirmLoginSubmit();
        break;
      case AUTH_MODE.DEFAULT:
      default:
        await onWebConfirmLoginSubmit();
    }
  };

  const onWebConfirmLoginSubmit = async (): Promise<void> => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.WEBCONFIRMLOGIN,
      requestParams: getConfirmLoginWebParams(),
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onWebConfirmLoginSuccess,
      noop
    );
  };

  const onWebConfirmLoginSuccess = (response: WebLoginSuccessResponse): void => {
    if (response.pendingMFA) {
      openTwoFactorAuthenticationVerificationModal({});
    }
  };

  const onAsyncConfirmLoginSuccess = (response: AsyncLoginSuccessResponse): void => {
    processAsyncLoginSuccessResponse(response, onAuthFormSubmitSuccess);
  };

  const onAsyncConfirmLoginSubmit = async (): Promise<void> => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.ASYNCCONFIRMLOGIN,
      requestParams: getAsyncConfirmLoginParams(),
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onAsyncConfirmLoginSuccess,
      onAsyncConfirmLoginError
    );
  };

  const onAsyncConfirmLoginError = (response: AsyncLoginFailResponse): void => {
    onLoginError(response.payload.data.reason, onPanelUpdate);
    resetGrecaptcha();
  };

  return (
    <>
      <div id="confirm-login-recaptcha-container" className={`spacing-m-b-8 ${styles.recaptchaContainer}`} />
      <AuthFormSubmitButton
        btnText={window.i18next.t('pmwjs_continue_login')}
        type={Type.PRIMARY}
        isDisabled={disableConfirmLoginButton}
        onClick={onSubmit}
        showSmallButton={showSmallButton}
        icon="icon-sign-in"
        customClasses="-fullwidth"
      />
    </>
  );
}
