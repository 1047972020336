import type {AsyncLoginSuccessResponse, AuthFormSubmitSuccessCallback, ProcessAsyncLoginSuccessType} from '@Components/login-page/login-page.types';
import {openTwoFactorAuthenticationVerificationModal} from '@Modals/two-factor-authentication-verification-modal/two-factor-authentication-verification-modal.library';

const useProcessAsyncLoginSuccessResponse = (): {processAsyncLoginSuccessResponse: ProcessAsyncLoginSuccessType} => {
  const processAsyncLoginSuccessResponse = (response: AsyncLoginSuccessResponse, callback: AuthFormSubmitSuccessCallback): void => {
    if (response.pendingMFA) {
      openTwoFactorAuthenticationVerificationModal({
        onVerificationSuccessCallback: callback.bind(response.id),
      });
    } else {
      callback(response.id);
    }
  };

  return {processAsyncLoginSuccessResponse};
};

export default useProcessAsyncLoginSuccessResponse;
