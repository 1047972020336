import React from "react";
import {setRecaptchaSelector} from "@Libraries/login-signup-library";

export default class Recaptcha {
    /**
     * Login from the website, i.e. authenticate/showlogin
     */
    static ACTION_WEBLOGIN = 'weblogin';

    /**
     * Login from the editor
     */
    static ACTION_ASYNCLOGIN = 'asynclogin';

    /**
     * Login by oauth
     */
    static ACTION_OAUTHLOGIN = 'oauthlogin';

    /**
     * Signup from the website, i.e. authenticate/showlogin
     */
    static ACTION_WEBSIGNUP = 'websignup';

    /**
     * Signup from the editor
     */
    static ACTION_ASYNCSIGNUP = 'asyncsignup';

    /**
     * Signup by oauth
     */
    static ACTION_OAUTHSIGNUP = 'oauthsignup';
}

export const initRecaptcha = (cb) => {
    if (!PMW.isRecaptchaInit) {
        PMW.isRecaptchaInit = true;

        const onGrecaptchaV3Ready = () => {
            window.grecaptcha.ready(cb);
        };

        var script = document.createElement('script'),
            m = document.getElementsByTagName('script')[0];

        script.async = 1;
        script.onload = onGrecaptchaV3Ready;
        script.src = '//www.recaptcha.net/recaptcha/api.js?render=' + PMW.RECAPTCHA_V3_KEY;
        m.parentNode.insertBefore(script, m);
    }
};

export const initRecaptchaV2 = (cb) => {
    let script = document.createElement('script'),
        m = document.getElementsByTagName('script')[0];

    script.async = 1;
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.onload = cb;
    m.parentNode.insertBefore(script, m);
}

export const onRecaptchaV2Load = (successCallback, errorCallback) => {
    setTimeout(function () {
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
            onRecaptchaV2Load(successCallback, errorCallback);
        } else {
            grecaptcha.render('confirm-login-recaptcha-container', {
                'sitekey': PMW.RECAPTCHA_V2_KEY,
                'callback': successCallback,
                'error-callback': errorCallback
            });
        }
    }.bind(this), 100);
}

export const getGrecaptchaToken = (cb, selector) => {
    let inputRecaptcha = $('.' + selector);
    setRecaptchaSelector(inputRecaptcha);
    grecaptcha.execute(PMW.RECAPTCHA_V3_KEY, {action: inputRecaptcha.attr('data-recaptcha-action')})
        .then(cb);
}

export const resetGrecaptcha = () => {
    grecaptcha.reset();
}