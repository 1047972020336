import type {ReactNode} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import styles from './line-divider.module.scss';

interface LineDividerProps {
  className?: string;
  dividerClassName?: string;
  includeTextInDivider?: boolean;
}

export function LineDivider({className = '', dividerClassName = '', includeTextInDivider = false}: LineDividerProps) {
  const getLineDivider = (): ReactNode => {
    return includeTextInDivider ? getLineDividerWithText() : getLineDividerWithoutText();
  };

  const getLineDividerWithText = (): ReactNode => {
    return (
      <>
        <div className={`${styles.lineDivider} border-s-standard ${dividerClassName}`} />
        <Text val={window.i18next.t('pmwjs_login_divider_or')} size={TextSize.SMALL} className="content-sub-text" />
        <div className={`${styles.lineDivider} border-s-standard ${dividerClassName}`} />
      </>
    );
  };

  const getLineDividerWithoutText = (): ReactNode => {
    return <div className={`${styles.lineDivider} border-s-standard ${dividerClassName}`} />;
  };

  return <div className={`${styles.container} ${className}`}>{getLineDivider()}</div>;
}
