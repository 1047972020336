import type {ReactElement} from 'react';
import React from 'react';
import {GoogleLoginButton} from '@Components/google-login-button';
import {ButtonFacebookLogin} from '@Components/button-facebook-login';
import type {FacebookConnectCallback, GoogleSignInCallback} from '@Components/login-page/login-page.types';
import {useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import {isSignupAuthType} from '@Libraries/login-signup-library';
import {noop} from '@Utils/general.util';
import {AppleLogin} from '@Components/apple-login';

interface ThirdPartyLoginButtonsProps {
  hideThirdPartyButtons: boolean;
  onGoogleSignInDone?: GoogleSignInCallback;
  onAppleSignInDone?: (userId: string) => void;
  onFacebookConnect?: FacebookConnectCallback;
  googleCustomClassname?: string;
}

function ThirdPartyLoginButtons({
  hideThirdPartyButtons,
  onAppleSignInDone = noop,
  onGoogleSignInDone = noop,
  onFacebookConnect = noop,
  googleCustomClassname,
}: ThirdPartyLoginButtonsProps): ReactElement {
  const type = useLoginPageAuthType();

  return (
    <>
      <GoogleLoginButton onSignInDoneCallback={onGoogleSignInDone} hideButton={hideThirdPartyButtons} containerClassname={googleCustomClassname} />
      <ButtonFacebookLogin onConnect={onFacebookConnect} isSignup={isSignupAuthType(type)} />
      <AppleLogin isSignup={isSignupAuthType(type)} onAppleSignInDone={onAppleSignInDone} />
    </>
  );
}

export default React.memo(ThirdPartyLoginButtons);
