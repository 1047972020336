import type {MutableRefObject, ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import type {FacebookConnectCallback} from '@Components/login-page/login-page.types';
import {isIOSAppOrEmbeddedEditor} from '@Utils/browser.util';
import {deleteCookie, readCookie} from '@Utils/cookie.util';
import {GA4EventName} from '@Libraries/ga-events';
import {Text, TextSize} from '@Components/text';
import {openErrorModal} from '@Modals/error-modal';
import {noop} from '@Utils/general.util';
import {getStateFromUrl} from '@Utils/url.util';
import {openLoginWindow} from '@Libraries/login-signup-library';
import type {AjaxSuccessResponse} from '@Utils/ajax.util';
import {Button, Size} from '../button';
import styles from './button-facebook-login.module.scss';

interface ButtonFacebookLoginProps {
  onConnect?: FacebookConnectCallback;
  isSignup?: boolean;
  isConnect?: boolean;
  isDisconnect?: boolean;
}

const OAUTH_DATA_COOKIE = 'pmwsac';

function ButtonFacebookLogin({onConnect = noop, isSignup = false, isConnect = false, isDisconnect = false}: ButtonFacebookLoginProps): ReactElement {
  const interval: MutableRefObject<NodeJS.Timeout | null> = useRef(null);
  const state = useRef('');

  useEffect(() => {
    return (): void => {
      clearCookieInterval();
    };
  }, []);

  const clearCookieInterval = (): void => {
    if (interval.current) {
      clearInterval(interval.current);
      deleteCookie(OAUTH_DATA_COOKIE);
    }
  };

  const onLoginUrlFetched = (url: string): void => {
    clearCookieInterval();
    state.current = getStateFromUrl(url);
  };

  const checkLoggedInStatus = async (): Promise<void> => {
    const response = (await window.PMW.pollingAjaxCallAsync(
      async () => {},
      () => {
        return window.PMW.readLocal('authenticate/hasAccountBeenLoggedIn', {
          state: state.current,
        });
      }
    )) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, '', onConnect, GA4EventName.SIGN_UP_FACEBOOK);
  };

  const handleExistingAccount = async (identityToken: string, email: string, password: string): Promise<void> => {
    const response = (await window.PMW.writeLocal('authenticate/handleExistingSSOAccount', {
      identityToken,
      email,
      password,
      source: GA4EventName.SIGN_UP_FACEBOOK,
    })) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, '', onConnect, GA4EventName.SIGN_UP_FACEBOOK);
  };

  const handleFacebookSignIn = async (): Promise<void> => {
    const loginWindow = await openLoginWindow('authenticate/getFacebookOauthUrl', onLoginUrlFetched);
    if (isIOSAppOrEmbeddedEditor()) {
      void checkLoggedInStatus();
      return;
    }
    interval.current = setInterval(() => {
      const cookie = readCookie(OAUTH_DATA_COOKIE);
      if (cookie) {
        clearCookieInterval();
        loginWindow?.close();
        window.PMW.ssoAccountAuthenticationCallback(JSON.parse(decodeURIComponent(cookie)), handleExistingAccount, '', onConnect, GA4EventName.SIGN_UP_FACEBOOK);
      }
    }, 500);
  };

  const getCustomClassNames = (): string => {
    return `${styles.fbLoginBtn} -fullwidth -noun js-fb-login-btn`;
  };

  const getButtonText = (): string => {
    return isSignup ? window.i18next.t('pmwjs_user_facebook_signup') : window.i18next.t('pmwjs_user_facebook_login');
  };

  const disconnectFacebookAccount = (): void => {
    void window.PMW.write(window.PMW.util.site_url('user/disconnectFacebookAccount'), null, (response: AjaxSuccessResponse) => {
      if (response.status === 'success') {
        window.location.href = window.PMW.util.site_url('user/editprofile');
      } else {
        openErrorModal({message: window.i18next.t('pmwjs_error_processing_request_try_again')});
      }
    });
  };

  const handleFacebookConnect = async (): Promise<void> => {
    const loginWindow = await openLoginWindow('authenticate/getFacebookOauthUrl', onLoginUrlFetched);
    if (isIOSAppOrEmbeddedEditor()) {
      void checkLoggedInStatus();
      return;
    }
    interval.current = setInterval(() => {
      const cookie = readCookie(OAUTH_DATA_COOKIE);
      if (cookie) {
        clearCookieInterval();
        loginWindow?.close();
        window.PMW.ssoAccountAuthenticationCallback(JSON.parse(decodeURIComponent(cookie)), noop, '', onConnect, GA4EventName.SIGN_UP_FACEBOOK);
      }
    }, 500);
  };

  if (isDisconnect) {
    return <Text val={window.i18next.t('pmwjs_disconnect')} size={TextSize.XSMALL} onClick={disconnectFacebookAccount} />;
  }

  if (isConnect) {
    return (
      <Text
        val={window.i18next.t('pmwjs_connect')}
        size={TextSize.XSMALL}
        onClick={(): void => {
          void handleFacebookConnect();
        }}
      />
    );
  }

  return (
    <Button
      size={Size.SMALL}
      text={getButtonText()}
      onClick={handleFacebookSignIn}
      customClasses={getCustomClassNames()}
      textClasses={`${styles.fbLoginText} body-s-bold`}
      icon="icon-fb-circle"
      iconClassName="content-body-white"
    />
  );
}

export default React.memo(ButtonFacebookLogin);
