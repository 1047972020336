import type {MutableRefObject, ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import {Button, Size, Type} from '@Components/button';
import {deleteCookie} from '@Utils/cookie.util';
import {Text, TextSize} from '@Components/text';
import {openErrorModal} from '@Modals/error-modal';
import {GA4EventName} from '@Libraries/ga-events';
import {isIOSAppOrEmbeddedEditor} from '@Utils/browser.util';
import {getStateFromUrl} from '@Utils/url.util';
import {openLoginWindow} from '@Libraries/login-signup-library';
import type {AjaxSuccessResponse} from '@Utils/ajax.util';
import styles from './apple-login.module.scss';

const OAUTH_DATA_COOKIE = 'pmwsac';
const EVENT_RESPONSE_OAUTH_DONE = 'oauthDone';
const OAUTH_ORIGIN = 'https://appleid.apple.com';

interface EventResponse {
  method: string;
  data: {
    authorization: {
      code: string;
      state: string;
    };
  };
}

export interface AppleLoginProps {
  redirectUrl?: string;
  isSignup?: boolean;
  isConnect?: boolean;
  isDisconnect?: boolean;

  onAppleSignInDone?(userId: string): void;
}

export function AppleLogin({...props}: AppleLoginProps): ReactElement | null {
  const interval: MutableRefObject<NodeJS.Timeout | null> = useRef(null);
  const state = useRef('');

  useEffect(() => {
    return (): void => {
      clearCookieInterval();
    };
  }, []);

  const getButtonText = (): string => {
    if (props.isSignup) {
      return window.i18next.t('pmwjs_user_apple_signup');
    }
    return window.i18next.t('pmwjs_user_apple_login');
  };

  const clearCookieInterval = (): void => {
    if (interval.current) {
      clearInterval(interval.current);
      deleteCookie(OAUTH_DATA_COOKIE);
    }
  };

  const onLoginUrlFetched = (url: string): void => {
    clearCookieInterval();
    state.current = getStateFromUrl(url);
  };

  const handleExistingAccount = async (identityToken: string, email: string, password: string): Promise<void> => {
    const response = (await window.PMW.writeLocal('authenticate/handleExistingSSOAccount', {
      identityToken,
      email,
      password,
      source: GA4EventName.SIGN_UP_APPLE,
    })) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, props.redirectUrl, props.onAppleSignInDone?.bind(null), GA4EventName.SIGN_UP_APPLE);
  };

  const checkLoggedInStatus = async (): Promise<void> => {
    const response = (await window.PMW.pollingAjaxCallAsync(
      async () => {},
      () => {
        return window.PMW.readLocal('authenticate/hasAccountBeenLoggedIn', {
          state: state.current,
        });
      }
    )) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, props.redirectUrl, props.onAppleSignInDone?.bind(null), GA4EventName.SIGN_UP_APPLE);
  };

  const eventHandler = (loginWindow: Window | null, event: MessageEvent): void => {
    if (event.origin !== OAUTH_ORIGIN) {
      return;
    }
    const eventData = JSON.parse(event.data as string) as EventResponse;
    if (eventData.data?.authorization && eventData.method === EVENT_RESPONSE_OAUTH_DONE) {
      void window.PMW.writeLocal('authenticate/appleOauthCallback', {
        state: eventData.data.authorization.state,
        code: eventData.data.authorization.code,
      }).then((response) => {
        loginWindow?.close();
        window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, props.redirectUrl, props.onAppleSignInDone?.bind(null), GA4EventName.SIGN_UP_APPLE);
      });
      window.removeEventListener('message', eventHandler.bind(null, null));
    }
  };

  const handleAppleSignIn = async (): Promise<void> => {
    const loginWindow = await openLoginWindow('authenticate/getAppleOauthUrl', onLoginUrlFetched);
    if (isIOSAppOrEmbeddedEditor()) {
      void checkLoggedInStatus();
      return;
    }
    window.addEventListener('message', eventHandler.bind(null, loginWindow));
  };

  const disconnectAppleAccount = (): void => {
    void window.PMW.write(window.PMW.util.site_url('user/disconnectAppleAccount'), null, (response: AjaxSuccessResponse) => {
      if (response.status === 'success') {
        window.location.href = window.PMW.util.site_url('user/editprofile');
      } else {
        openErrorModal({message: window.i18next.t('pmwjs_error_processing_request_try_again')});
      }
    });
  };

  if (props.isDisconnect) {
    return (
      <Text
        val={window.i18next.t('pmwjs_disconnect')}
        size={TextSize.XSMALL}
        onClick={(): void => {
          disconnectAppleAccount();
        }}
      />
    );
  }

  if (props.isConnect) {
    return (
      <Text
        val={window.i18next.t('pmwjs_connect')}
        size={TextSize.XSMALL}
        onClick={(): void => {
          void handleAppleSignIn();
        }}
      />
    );
  }

  return (
    <div className={`radius-4 spacing-m-t-3 ${styles.btnContainer}`}>
      <Button type={Type.DARK_GHOST} size={Size.SMALL} icon="icon-apple" customClasses="_full-height -noun" isFullWidth text={getButtonText()} onClick={handleAppleSignIn} />
    </div>
  );
}
