import type {ReactElement} from 'react';
import React from 'react';
import styles from './signup-agreement.module.scss';
import type {TextSize} from '../../../text';
import {Text} from '../../../text';

interface SignupAgreementProps {
  textSize: TextSize;
  isTextCenterAligned?: boolean;
}

function SignupAgreement({textSize, isTextCenterAligned = false}: SignupAgreementProps): ReactElement {
  const getAgreementClasses = (): string => {
    const classes = ['spacing-m-t-4'];

    if (isTextCenterAligned) {
      classes.push('text-center');
    }

    return classes.join(' ');
  };

  const getTextClasses = (): string => {
    const textClasses = [styles.agreement];

    if (isTextCenterAligned) {
      textClasses.push(styles.inlineText);
      textClasses.push('spacing-p-l-4 spacing-p-r-4');
    }

    return textClasses.join(' ');
  };

  return (
    <div className={getAgreementClasses()}>
      <Text
        val={window.i18next.t('pmwjs_signup_terms_of_use', {
          link: window.PMW.util.site_url('info/termsofuse'),
          linkClass: `${styles.link} hover-transition-all`,
        })}
        className={getTextClasses()}
        dangerouslySetInnerHTML
        size={textSize}
      />
    </div>
  );
}

export default React.memo(SignupAgreement);
