import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import {getGrecaptchaToken} from '@Libraries/recaptcha-library';
import {validateUserPassword} from '@Libraries/user.library';
import {
  AUTH_MODE,
  EMAIL_PLACEHOLDER,
  getCommonAuthFormParams,
  getConfirmPasswordStatus,
  getPasswordFieldStatus,
  getRecaptchaSelectorValue,
  getStatusForPasswordLength,
  getStatusForSpecialCharactersInPassword,
  handleAuthFormSubmit,
  PASSWORD_PLACEHOLDER,
  redirectToPage,
} from '@Libraries/login-signup-library';
import {executeThunk} from '@Utils/thunk.util';
import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import type {AsyncSignupParams, AuthFormSubmissionParams, BaseAuthFormProps, WebSignupParams} from '@Components/login-page/login-page.types';
import {AuthFormSubmissionType} from '@Components/login-page/login-page.types';
import {PasswordPolicyItem} from '@Components/signup-form/components/password-policy-item';
import {CSSTransition} from 'react-transition-group';
import {useLoginPageAuthButtonsDisabled, useLoginPageAuthMode} from '@Components/login-page/login-page.hooks';
import {isValidEmail} from '@Utils/string.util';
import {useAppDispatch, useAppSelector} from '@/hooks';
import {SignupAgreement} from './components/signup-agreement';
import {AuthFormSubmitButton} from '../auth-form-submit-button';
import {EmailUpdateNotifications} from './components/email-update-notifications';
import {Type} from '../button';
import {TextSize} from '../text';
import {INPUT_FIELD_TYPE} from '../input-field';
import {AuthInput} from '../auth-input';
import styles from './signup-form.module.scss';
import {useUpdateErrorStates} from '@/hooks/login-signup/useUpdateErrorStates';
import {PASSWORD_POLICY_ITEM_STATUS} from './signup-form.types';

interface SignupFormProps extends BaseAuthFormProps {
  isFooterTextCenterAligned?: boolean;
}

const PASSWORD_POLICY_ANIMATION_DURATION = 200;

const animationClassNames = {
  enter: styles.passwordPolicyTransitionEnter,
  enterActive: styles.passwordPolicyTransitionEnterActive,
  exit: styles.passwordPolicyTransitionExit,
  exitActive: styles.passwordPolicyTransitionExitActive,
};

const SIGNUP_RECAPTCHA_SELECTOR = 'js-g-recaptcha-response-signup';

export function SignupForm({showSmallButton = false, onAuthFormSubmitSuccess = $.noop, isFooterTextCenterAligned = false}: SignupFormProps): ReactElement {
  const disableSignupButton = useLoginPageAuthButtonsDisabled();
  const authMode = useLoginPageAuthMode();
  const dispatch = useAppDispatch();
  const {showErrors} = useUpdateErrorStates();
  const signupSource = useAppSelector((state) => {
    return state.loginPage.signupSource;
  });
  const signupActionType = useAppSelector((state) => {
    return state.loginPage.signupActionType;
  });
  const isInDataRegulatedLocation = useAppSelector((state) => {
    return state.loginPage.isInDataRegulatedLocation;
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailUpdates, setEmailUpdates] = useState(!isInDataRegulatedLocation);
  const [showPasswordFieldError, setShowPasswordFieldError] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [showPasswordMismatchError, setShowPasswordMismatchError] = useState(false);
  const [passwordFieldIcon, setPasswordFieldIcon] = useState('icon-eye-off');
  const [showPasswordPolicyCriteria, setShowPasswordPolicyCriteria] = useState(false);
  const [passwordHasFocus, setPasswordHasFocus] = useState(false);
  const [confirmPasswordHasFocus, setConfirmPasswordHasFocus] = useState(false);

  useEffect(() => {
    setShowPasswordPolicyCriteria(shouldPasswordPolicyBeShown);
  }, [passwordHasFocus, password]);

  const handleEmailInputChange = React.useCallback((txt: string) => {
    setEmail(txt.trim());
  }, []);

  const setPasswordMismatchErrorState = (state: boolean): void => {
    setShowPasswordMismatchError(state);
  };

  const handlePasswordInputChange = React.useCallback(
    (txt: string) => {
      setDoPasswordsMatch(txt === confirmPassword);
      setPasswordMismatchErrorState(!(txt === confirmPassword));
      setPassword(txt.trim());
    },
    [showPasswordMismatchError, confirmPassword]
  );

  const showError = (): boolean => {
    return showPasswordFieldError || showPasswordMismatchError;
  };

  const getNewPasswordFieldIcon = (icon: string): string => {
    return icon === 'icon-eye' ? 'icon-eye-off' : 'icon-eye';
  };

  const onPasswordEyeIconClick = React.useCallback(() => {
    setPasswordFieldIcon(getNewPasswordFieldIcon(passwordFieldIcon));
  }, [passwordFieldIcon]);

  const handleConfirmPasswordInputChange = React.useCallback(
    (txt: string) => {
      if (showPasswordMismatchError) {
        setPasswordMismatchErrorState(false);
      }

      setConfirmPassword(txt.trim());

      if (!validateUserPassword(password)) {
        return;
      }

      handlePasswordsComparison(txt);
    },
    [showPasswordMismatchError, password]
  );

  const handlePasswordsComparison = (txt: string): void => {
    if (txt !== password && txt.length > 0) {
      setShowPasswordFieldError(true);
      setDoPasswordsMatch(false);
    } else {
      setShowPasswordFieldError(false);
      if (txt.length > 0) {
        setDoPasswordsMatch(true);
      }
    }
  };

  const shouldPasswordPolicyBeShown = (): boolean => {
    return passwordHasFocus || (!passwordHasFocus && password.length > 0);
  };

  const onSubmit = () => {
    const isInvalidEmail = !isValidEmail(email);
    setIsEmailInvalid(isInvalidEmail);
    if (isInvalidEmail) {
      return;
    }

    if (!validateUserPassword(password) || confirmPassword.length === 0) {
      setShowPasswordMismatchError(true);
      return;
    }

    if (password !== confirmPassword) {
      return;
    }

    if (window.PMW.grecaptchaReady) {
      getGrecaptchaToken(handleAuthFormSubmit.bind(null, onSignupSubmit), SIGNUP_RECAPTCHA_SELECTOR);
    }
  };

  const onCheckboxClick = React.useCallback(() => {
    setEmailUpdates(!emailUpdates);
  }, [emailUpdates]);

  const getAgreementTextSize = (): TextSize => {
    return showSmallButton ? TextSize.XXSMALL : TextSize.XSMALL;
  };

  const getWebSignupParams = () => {
    const webSignupParams: WebSignupParams = {
      email,
      password,
      confirmPassword,
      emailUpdates,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
      ...getCommonAuthFormParams(),
    };
    return webSignupParams;
  };

  const getAsyncSignupParams = () => {
    const asyncSignupParams: AsyncSignupParams = {
      email,
      password,
      confirmPassword,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
      signupSource,
      emailUpdates,
    };
    return asyncSignupParams;
  };

  const onSignupSubmit = async () => {
    switch (authMode) {
      case AUTH_MODE.ASYNC:
        await onAsyncSignupSubmit();
        break;
      case AUTH_MODE.DEFAULT:
        await onWebSignupSubmit();
        break;
    }
  };

  const onWebSignupSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.WEBSIGNUP,
      requestParams: getWebSignupParams(),
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onWebSignupSuccess,
      handleWebSignupError
    );
  };

  const onAsyncSignupSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.ASYNCSIGNUP,
      requestParams: getAsyncSignupParams(),
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onAsyncSignupSuccess,
      handleAsyncSignupError
    );
  };

  const onWebSignupSuccess = (response: any): void => {
    window.PMW.gtm.trackSignupEvents(window.PMW.gtm.SIGNUP_LABELS.EMAIL);
    if (window.PMW.util.doesUrlContainEmbeddedEditorAuthenticationLayoverUri(response.redirectURL)) {
      window.close();
      return;
    }
    redirectToPage(window.PMW.util.site_url(response.redirectURL));
  };

  const onAsyncSignupSuccess = (): void => {
    window.PMW.gtm.trackSignupEvents(window.PMW.gtm.SIGNUP_LABELS.EMAIL);
    onAuthFormSubmitSuccess();
  };

  const handleWebSignupError = (response: any): void => {
    if (response.payload.data.redirectUrl === null || response.payload.data.redirectUrl === undefined) {
      showErrors(response.payload.data.message);
    } else if (response.payload.data.redirectUrl === 'authenticate/handleSignupError') {
      redirectToPage(window.PMW.util.site_url(`${response.payload.data.redirectUrl}?message=${response.payload.data.message}`));
    } else {
      redirectToPage(window.PMW.util.site_url(response.payload.data.redirectUrl));
    }
  };

  const handleAsyncSignupError = (response: any): void => {
    let errorMessage = '';
    const errRes = response.payload;

    if (errRes.status === 'fail' && errRes.data) {
      errorMessage = getErrorMessageFromAsyncSignupResponse(errRes.data);
    } else {
      errorMessage = window.i18next.t('pmwjs_unknown_error');
    }

    if (errorMessage) {
      showErrors(errorMessage);
    }
  };

  const showPasswordMatchMessage = (): boolean => {
    return confirmPassword.length > 0 && password.length > 8;
  };

  const onPasswordFocus = (): void => {
    setPasswordHasFocus(true);
  };

  const onPasswordFocusOut = (): void => {
    setPasswordHasFocus(false);
  };

  const onConfirmPasswordFocus = (): void => {
    setConfirmPasswordHasFocus(true);
  };

  const onConfirmPasswordFocusOut = (): void => {
    setConfirmPasswordHasFocus(false);
  };

  const onEmailFocusOut = (): void => {
    setIsEmailInvalid(!isValidEmail(email));
  };

  const getPasswordPolicyCriteria = (): ReactNode => {
    return (
      <CSSTransition in={showPasswordPolicyCriteria} timeout={PASSWORD_POLICY_ANIMATION_DURATION} unmountOnExit classNames={animationClassNames}>
        <div className={styles.passwordPolicyContainer}>
          <PasswordPolicyItem text={window.i18next.t('pmwjs_password_policy_length')} status={getStatusForPasswordLength(password, passwordHasFocus)} />
          <PasswordPolicyItem text={window.i18next.t('pmwjs_password_policy_characters')} status={getStatusForSpecialCharactersInPassword(password, passwordHasFocus)} />
        </div>
      </CSSTransition>
    );
  };

  const getPasswordMatchResult = (): ReactNode => {
    if (doPasswordsMatch) {
      return (
        <div className={styles.passwordPolicyContainer}>
          <PasswordPolicyItem text={window.i18next.t('pmwjs_passwords_match')} status={PASSWORD_POLICY_ITEM_STATUS.MATCH} />
        </div>
      );
    }

    if (showError()) {
      return (
        <div className={styles.passwordPolicyContainer}>
          <PasswordPolicyItem text={window.i18next.t('pmwjs_passwords_do_not_match')} status={PASSWORD_POLICY_ITEM_STATUS.MISMATCH} />
        </div>
      );
    }
    return undefined;
  };

  const getFormInputFields = (): ReactNode => {
    return (
      <>
        <AuthInput
          label={window.i18next.t('pmwjs_email')}
          placeholder={EMAIL_PLACEHOLDER}
          type={INPUT_FIELD_TYPE.EMAIL}
          handleInput={handleEmailInputChange}
          showError={isEmailInvalid}
          onInputSubmit={onSubmit}
          errorText={window.i18next.t('pmwjs_valid_email_address')}
          onFocusOutCallback={onEmailFocusOut}
        />

        <div className={styles.passwordFieldContainer}>
          <AuthInput
            label={window.i18next.t('pmwjs_password')}
            type={INPUT_FIELD_TYPE.PASSWORD}
            placeholder={PASSWORD_PLACEHOLDER}
            handleInput={handlePasswordInputChange}
            onPasswordIconEyeClick={onPasswordEyeIconClick}
            passwordFieldIcon={passwordFieldIcon}
            onInputSubmit={onSubmit}
            onFocusCallback={onPasswordFocus}
            onFocusOutCallback={onPasswordFocusOut}
            passwordStatus={getPasswordFieldStatus(password, confirmPassword, passwordHasFocus)}
          />
          {getPasswordPolicyCriteria()}
        </div>

        <div className={styles.confirmPasswordContainer}>
          <AuthInput
            label={window.i18next.t('pmwjs_confirm_password')}
            type={INPUT_FIELD_TYPE.PASSWORD}
            placeholder={PASSWORD_PLACEHOLDER}
            handleInput={handleConfirmPasswordInputChange}
            onPasswordIconEyeClick={onPasswordEyeIconClick}
            passwordFieldIcon={passwordFieldIcon}
            onInputSubmit={onSubmit}
            onFocusCallback={onConfirmPasswordFocus}
            onFocusOutCallback={onConfirmPasswordFocusOut}
            passwordStatus={getConfirmPasswordStatus(password, confirmPassword, passwordHasFocus, confirmPasswordHasFocus)}
          />

          {showPasswordMatchMessage() ? getPasswordMatchResult() : null}
        </div>
      </>
    );
  };

  return (
    <>
      <input type="hidden" name="gRecaptchaResponse" className={`g-recaptcha-response ${SIGNUP_RECAPTCHA_SELECTOR}`} data-recaptcha-action={signupActionType} />
      {getFormInputFields()}
      <EmailUpdateNotifications onCheckboxClick={onCheckboxClick} emailUpdates={emailUpdates} />
      <AuthFormSubmitButton
        btnText={window.i18next.t('pmwjs_sign_up_action')}
        type={Type.PRIMARY}
        showSmallButton={showSmallButton}
        customClasses="-fullwidth"
        onClick={onSubmit}
        isDisabled={disableSignupButton}
      />
      <SignupAgreement textSize={getAgreementTextSize()} isTextCenterAligned={isFooterTextCenterAligned} />
    </>
  );
}

const getErrorMessageFromAsyncSignupResponse = (data: any): string => {
  let errorMessage = '';
  if (data.passwordErrorMsg !== undefined && data.passwordErrorMsg !== '') {
    errorMessage = data.passwordErrorMsg;
  } else if (data.location === 'blocked') {
    errorMessage = window.i18next.t('pmwjs_auth_country_blocked');
  } else if (data.email === 'exists') {
    let text = 'pmwjs_email_exists';
    if (data.fbLinked && data.fbLinked === '1') {
      text = 'pmwjs_email_exists_fb';
    }
    if (data.fbLinked && data.googleLinked === '1') {
      text = 'pmwjs_email_exists_google';
    }
    if (data.googleLinked && data.fbLinked && data.googleLinked === '1' && data.fbLinked === '1') {
      text = 'pmwjs_email_exists_google_fb';
    }
    errorMessage = window.i18next.t(text, {
      supportLink: window.PMW.util.site_url('user/feedbackform'),
      className: 'content-danger',
    });
  } else if (data.email === 'invalid' || data.params === 'missing') {
    errorMessage = window.i18next.t('pmwjs_signup_invalid_params_error');
  } else if (data.action === 'reject') {
    errorMessage = data.message;
  }
  return errorMessage;
};
