import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {PMW_SOCIAL_MEDIA_URL_FACEBOOK, PMW_SOCIAL_MEDIA_URL_INSTAGRAM, PMW_SOCIAL_MEDIA_URL_TWITTER, PMW_SOCIAL_MEDIA_URL_YOUTUBE} from '@Libraries/social-media';
import styles from './social-media-icon.module.scss';

export enum SocialMediaIconType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export interface SocialMediaIconProps {
  type: SocialMediaIconType;
}

export function SocialMediaIcon({type}: SocialMediaIconProps): ReactElement {
  const getSocialMediaUrl = (): string => {
    switch (type) {
      case SocialMediaIconType.FACEBOOK:
        return PMW_SOCIAL_MEDIA_URL_FACEBOOK;
      case SocialMediaIconType.INSTAGRAM:
        return PMW_SOCIAL_MEDIA_URL_INSTAGRAM;
      case SocialMediaIconType.TWITTER:
        return PMW_SOCIAL_MEDIA_URL_TWITTER;
      case SocialMediaIconType.YOUTUBE:
        return PMW_SOCIAL_MEDIA_URL_YOUTUBE;
      default:
        return '';
    }
  };

  const getSocialMediaIconName = (): string => {
    switch (type) {
      case SocialMediaIconType.FACEBOOK:
        return `icon-${SocialMediaIconType.FACEBOOK}`;
      case SocialMediaIconType.INSTAGRAM:
        return `icon-${SocialMediaIconType.INSTAGRAM}`;
      case SocialMediaIconType.TWITTER:
        return `icon-${SocialMediaIconType.TWITTER}`;
      case SocialMediaIconType.YOUTUBE:
        return `icon-${SocialMediaIconType.YOUTUBE}`;
      default:
        return '';
    }
  };

  const getTypeSpecificClassName = (): string => {
    switch (type) {
      case SocialMediaIconType.FACEBOOK:
        return styles.facebook;
      case SocialMediaIconType.INSTAGRAM:
        return styles.instagram;
      case SocialMediaIconType.TWITTER:
        return styles.twitter;
      case SocialMediaIconType.YOUTUBE:
        return styles.youtube;
      default:
        return '';
    }
  };

  return (
    <a href={getSocialMediaUrl()} target="_blank" rel="noreferrer">
      <Icon
        icon={getSocialMediaIconName()}
        size={IconSize.SIZE_ICON_20}
        type={IconType.NONE}
        shape={IconShape.CIRCLE}
        className={`${styles.socialMediaIcon} ${styles.strokeIcons} ${getTypeSpecificClassName()}`}
      />
    </a>
  );
}
