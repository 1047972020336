import type {MutableRefObject, ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import type {GoogleSignInCallback} from '@Components/login-page/login-page.types';
import {isIOSAppOrEmbeddedEditor} from '@Utils/browser.util';
import {deleteCookie, readCookie} from '@Utils/cookie.util';
import {GA4EventName} from '@Libraries/ga-events';
import {noop} from '@Utils/general.util';
import {Text, TextSize} from '@Components/text';
import {openErrorModal} from '@Modals/error-modal';
import {getStateFromUrl} from '@Utils/url.util';
import {Image} from 'react-bootstrap';
import {openLoginWindow} from '@Libraries/login-signup-library';
import type {AjaxSuccessResponse} from '@Utils/ajax.util';
import {Button, Size} from '../button';
import styles from './google-login-button.module.scss';

interface GoogleLoginButtonProps {
  onSignInDoneCallback?: GoogleSignInCallback;
  hideButton?: boolean;
  redirectUrl?: string;
  containerClassname?: string;
  isConnect?: boolean;
  isDisconnect?: boolean;
}

const OAUTH_DATA_COOKIE = 'pmwsac';

function GoogleLoginButton({onSignInDoneCallback = noop, hideButton = false, redirectUrl = '', containerClassname, ...props}: GoogleLoginButtonProps): ReactElement {
  const state = useRef('');
  const interval: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  useEffect(() => {
    return (): void => {
      clearCookieInterval();
    };
  }, []);

  const clearCookieInterval = (): void => {
    if (interval.current) {
      clearInterval(interval.current);
      deleteCookie(OAUTH_DATA_COOKIE);
    }
  };

  const onLoginUrlFetched = (url: string): void => {
    clearCookieInterval();
    state.current = getStateFromUrl(url);
  };

  const getGoogleButtonClasses = (): string => {
    return `btn -login-btn -noun -fullwidth content-body-white ${styles.button}`;
  };

  const getContainerClassName = (): string => {
    if (containerClassname) {
      return containerClassname;
    }
    return styles.buttonContainer;
  };

  const handleExistingAccount = async (identityToken: string, email: string, password: string): Promise<void> => {
    const response = (await window.PMW.writeLocal('authenticate/handleExistingSSOAccount', {
      identityToken,
      email,
      password,
      source: GA4EventName.SIGN_UP_GOOGLE,
    })) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, redirectUrl, onSignInDoneCallback, GA4EventName.SIGN_UP_GOOGLE);
  };

  const checkLoggedInStatus = async (): Promise<void> => {
    const response = (await window.PMW.pollingAjaxCallAsync(
      async () => {},
      () => {
        return window.PMW.readLocal('authenticate/hasAccountBeenLoggedIn', {
          state: state.current,
        });
      }
    )) as AjaxSuccessResponse;
    window.PMW.ssoAccountAuthenticationCallback(response, handleExistingAccount, redirectUrl, onSignInDoneCallback, GA4EventName.SIGN_UP_GOOGLE);
  };

  const handleGoogleSignIn = async (): Promise<void> => {
    const loginWindow = await openLoginWindow('authenticate/getGoogleOauthUrl', onLoginUrlFetched);
    if (isIOSAppOrEmbeddedEditor()) {
      void checkLoggedInStatus();
      return;
    }
    interval.current = setInterval(() => {
      const cookie = readCookie(OAUTH_DATA_COOKIE);
      if (cookie) {
        clearCookieInterval();
        loginWindow?.close();
        window.PMW.ssoAccountAuthenticationCallback(JSON.parse(decodeURIComponent(cookie)), handleExistingAccount, redirectUrl, onSignInDoneCallback, GA4EventName.SIGN_UP_GOOGLE);
      }
    }, 500);
  };

  const handleGoogleConnect = async (): Promise<void> => {
    const loginWindow = await openLoginWindow('authenticate/getGoogleOauthUrl', onLoginUrlFetched);
    if (isIOSAppOrEmbeddedEditor()) {
      void checkLoggedInStatus();
      return;
    }
    interval.current = setInterval(() => {
      const cookie = readCookie(OAUTH_DATA_COOKIE);
      if (cookie) {
        clearCookieInterval();
        loginWindow?.close();
        window.PMW.ssoAccountAuthenticationCallback(JSON.parse(decodeURIComponent(cookie)), noop, redirectUrl, onSignInDoneCallback, GA4EventName.SIGN_UP_GOOGLE);
      }
    }, 500);
  };

  const disconnectGoogleAccount = (): void => {
    void window.PMW.write(window.PMW.util.site_url('user/disconnectGoogleAccount'), null, (response: AjaxSuccessResponse) => {
      if (response.status === 'success') {
        window.location.href = window.PMW.util.site_url('user/editprofile');
      } else {
        openErrorModal({message: window.i18next.t('pmwjs_error_processing_request_try_again')});
      }
    });
  };

  if (props.isDisconnect) {
    return <Text val={window.i18next.t('pmwjs_disconnect')} size={TextSize.XSMALL} onClick={disconnectGoogleAccount} />;
  }

  if (props.isConnect) {
    return (
      <Text
        val={window.i18next.t('pmwjs_connect')}
        size={TextSize.XSMALL}
        onClick={(): void => {
          void handleGoogleConnect();
        }}
      />
    );
  }

  const getGoogleLogo = (): ReactElement => {
    return (
      <div>
        <div className={`radius-round flex-center ${styles.logo}`}>
          <Image src={window.PMW.util.asset_url('images/google-logo.png')} />
        </div>
      </div>
    );
  };

  return (
    <div className={getContainerClassName()}>
      {!hideButton ? (
        <Button size={Size.SMALL} text="Continue with Google" customClasses={getGoogleButtonClasses()} onClick={handleGoogleSignIn}>
          {getGoogleLogo()}
        </Button>
      ) : null}
    </div>
  );
}

export default React.memo(GoogleLoginButton);
