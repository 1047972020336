import type {ReactElement} from 'react';
import React from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {Text, TextSize} from '../../../text';
import styles from './panel-heading.module.scss';
import {Icon} from '../../../icon-v2';
import {noop} from '@Utils/general.util';

interface LoginPanelHeadingProps {
  showBackOption: boolean;
  heading: string;
  linkActionInfo?: string;
  linkText?: string;
  changePanelLayout?: VoidFunction;
  switchPanel?: VoidFunction;
}

export function PanelHeading({showBackOption, heading, linkText = '', linkActionInfo = '', changePanelLayout = noop, switchPanel = noop}: LoginPanelHeadingProps): ReactElement {
  const getPanelHeadingWithBackOption = (): ReactElement => {
    return (
      <div className={`flexbox ${styles.backHeadingContainer}`}>
        <Icon
          icon="icon-back"
          size={IconSize.SIZE_ICON_20}
          type={IconType.TRANSPARENT}
          shape={IconShape.SQUARE}
          onClick={changePanelLayout}
          className={`spacing-m-r-3 ${styles.iconContainer}`}
        />
        <div className="flex-center">
          <Text val={heading} size={TextSize.LARGE} bold />
        </div>
      </div>
    );
  };

  const getPanelHeadingWithoutBackOption = (): ReactElement => {
    return (
      <div className="spacing-m-b-13 flex-column-align-center">
        <Text val={heading} size={TextSize.LARGE} bold />
        <div className="flexbox">
          <Text val={linkActionInfo} size={TextSize.XSMALL} />
          <div className={`spacing-m-l-1 flex-row-justify-center ${styles.signupLink}`} onClick={switchPanel}>
            <Text val={linkText} size={TextSize.XSMALL} />
          </div>
        </div>
      </div>
    );
  };

  if (showBackOption) {
    return getPanelHeadingWithBackOption();
  }

  return getPanelHeadingWithoutBackOption();
}
