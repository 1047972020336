import React, {useEffect, useState} from 'react';
import {getGrecaptchaToken} from '@Libraries/recaptcha-library';
import {AUTH_MODE, getCommonAuthFormParams, getRecaptchaSelectorValue, handleAuthFormSubmit} from '@Libraries/login-signup-library';
import type {AsyncStudentLoginParams, AuthFormSubmissionParams, BaseAuthFormProps, VoidFunction, StudentWebLoginParams} from '@Components/login-page/login-page.types';
import {AuthFormSubmissionType} from '@Components/login-page/login-page.types';
import {executeThunk} from '@Utils/thunk.util';
import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import {useLoginPageAuthButtonsDisabled, useLoginPageAuthMode, useLoginPageLoginActionType} from '@Components/login-page/login-page.hooks';
import styles from './student-login-form.module.scss';
import {INPUT_FIELD_TYPE} from '../input-field';
import {AuthInput} from '../auth-input';
import {Type} from '../button';
import {Text, TextSize} from '../text';
import {AuthFormSubmitButton} from '../auth-form-submit-button';
import {StudentLoginInfoText} from './components/student-login-info-text';
import {useAppDispatch} from '@/hooks';

interface StudentLoginFormProps extends BaseAuthFormProps {
  loginRedirect: VoidFunction;
  isPanelScreen?: boolean;
  signupRedirect?: VoidFunction;
}

const STUDENT_LOGIN_RECAPTCHA_SELECTOR = 'js-g-recaptcha-response-student-login';

export function StudentLoginForm({
  loginRedirect,
  signupRedirect = $.noop,
  isPanelScreen = false,
  showSmallButton = false,
  onAuthFormSubmitSuccess = $.noop,
}: StudentLoginFormProps) {
  const [project, setProject] = useState('');
  const [showProjectNameError, setShowProjectNameError] = useState(false);

  const dispatch = useAppDispatch();
  const disableLoginButton = useLoginPageAuthButtonsDisabled();
  const authMode = useLoginPageAuthMode();
  const loginActionType = useLoginPageLoginActionType();

  useEffect(() => {
    const loginRedirectTag = document.querySelector('.js-student-login-redirect');
    const signupRedirectTag = document.querySelector('.js-student-signup-redirect');

    if (loginRedirectTag) {
      loginRedirectTag.addEventListener('click', loginRedirect);
    }

    if (signupRedirectTag) {
      signupRedirectTag.addEventListener('click', signupRedirect);
    }

    return () => {
      if (loginRedirectTag) {
        loginRedirectTag.removeEventListener('click', loginRedirect);
      }

      if (signupRedirectTag) {
        signupRedirectTag.removeEventListener('click', signupRedirect);
      }
    };
  });

  const handleProjectInputChange = React.useCallback((txt: string) => {
    setProject(txt.trim());
  }, []);

  const onSubmit = () => {
    if (project.length === 0) {
      setShowProjectNameError(true);
      return;
    }

    setShowProjectNameError(false);

    if (window.PMW.grecaptchaReady) {
      getGrecaptchaToken(handleAuthFormSubmit.bind(null, onStudentLoginSubmit), STUDENT_LOGIN_RECAPTCHA_SELECTOR);
    }
  };

  const getInfoTextSize = (): TextSize => {
    return showSmallButton ? TextSize.XSMALL : TextSize.SMALL;
  };

  const getStudentWebLoginParams = (): StudentWebLoginParams => {
    return {
      project,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
      isRecaptchaV2Confirmation: 0,
      studentlogin: 1,
      ...getCommonAuthFormParams(),
    };
  };

  const getAsyncStudentLoginParams = (): AsyncStudentLoginParams => {
    return {
      project,
      gRecaptchaResponse: getRecaptchaSelectorValue(),
    };
  };

  const onStudentLoginSubmit = async () => {
    switch (authMode) {
      case AUTH_MODE.ASYNC:
        await onAsyncStudentLoginSubmit();
        break;
      case AUTH_MODE.DEFAULT:
        await onWebStudentLoginSubmit();
        break;
    }
  };

  const onWebStudentLoginSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.WEBLOGIN,
      requestParams: getStudentWebLoginParams(),
    };

    await dispatch(submitAuthForm(authFormSubmissionParams));
  };

  const onAsyncStudentLoginSubmit = async () => {
    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: AuthFormSubmissionType.ASYNCSTUDENTLOGIN,
      requestParams: getAsyncStudentLoginParams(),
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onAuthFormSubmitSuccess,
      $.noop
    );
  };

  return (
    <>
      <input type="hidden" name="gRecaptchaResponse" className={`g-recaptcha-response ${STUDENT_LOGIN_RECAPTCHA_SELECTOR}`} data-recaptcha-action={loginActionType} />

      <AuthInput
        label={window.i18next.t('pmwjs_student_login_project_name')}
        placeholder={window.i18next.t('pmwjs_student_login_project_name_placeholder')}
        type={INPUT_FIELD_TYPE.EMAIL}
        handleInput={handleProjectInputChange}
        showError={showProjectNameError}
        onInputSubmit={onSubmit}
        errorText={window.i18next.t('pmwjs_missing_project_name')}
      />

      <AuthFormSubmitButton
        btnText={window.i18next.t('pmwjs_log_in')}
        type={Type.PRIMARY}
        onClick={onSubmit}
        customClasses="-fullwidth"
        showSmallButton={showSmallButton}
        containerClassName={styles.loginBtnContainer}
        icon="icon-sign-in"
        isDisabled={disableLoginButton}
      />

      <div className={styles.infoContainer}>
        <Text val={window.i18next.t('pmwjs_are_you_a_teacher')} size={getInfoTextSize()} className={styles.teacher} bold />
        <StudentLoginInfoText textSize={getInfoTextSize()} isPanelScreen={isPanelScreen} />
      </div>
    </>
  );
}
