import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {PASSWORD_POLICY_ITEM_STATUS} from '@Components/signup-form/signup-form.types';
import {Text, TextSize} from '../../../text';
import {Icon} from '../../../icon-v2';

interface PasswordPolicyItemProps {
  text: string;
  status: PASSWORD_POLICY_ITEM_STATUS;
}

export function PasswordPolicyItem({text, status}: PasswordPolicyItemProps) {
  const getIconName = (): string => {
    switch (status) {
      case PASSWORD_POLICY_ITEM_STATUS.COMPLETE:
      case PASSWORD_POLICY_ITEM_STATUS.MATCH:
        return 'icon-check-circle';
      case PASSWORD_POLICY_ITEM_STATUS.INCOMPLETE:
        return 'icon-x-circle';
      case PASSWORD_POLICY_ITEM_STATUS.MISMATCH:
        return 'icon-exclamation-triangle';
      case PASSWORD_POLICY_ITEM_STATUS.IN_PROGRESS:
      default:
        return 'icon-circle';
    }
  };

  const getClassNameForStatus = (): string => {
    switch (status) {
      case PASSWORD_POLICY_ITEM_STATUS.COMPLETE:
      case PASSWORD_POLICY_ITEM_STATUS.MATCH:
        return 'content-success';
      case PASSWORD_POLICY_ITEM_STATUS.INCOMPLETE:
      case PASSWORD_POLICY_ITEM_STATUS.MISMATCH:
        return 'content-danger';
      case PASSWORD_POLICY_ITEM_STATUS.IN_PROGRESS:
      default:
        return 'content-body';
    }
  };

  return (
    <div className={`flex-row-align-center ${getClassNameForStatus()}`}>
      <Icon icon={getIconName()} size={IconSize.SIZE_ICON_16} />
      <div className="spacing-m-l-1">
        <Text val={text} size={TextSize.XXSMALL} />
      </div>
    </div>
  );
}
