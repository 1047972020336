import type {ReactElement, ReactNode} from 'react';
import React from 'react';
import {LoginSignupPanel} from '@Components/login-signup-panel-container/components/login-signup-panel';
import {ThirdPartyLoginButtons} from '@Components/login-signup-panel-container/components/third-party-login-buttons';
import {LineDivider} from '@Components/line-divider';
import {
  areThirdPartyButtonsNeededForType,
  AUTH_SUB_TYPE,
  AUTH_TYPE,
  isLoginAuthType,
  isLoginOrSignupAuthType,
  isMobileScreen,
  isOAuthFlow,
  LOGIN_OPTIONS,
} from '@Libraries/login-signup-library';
import {AuthFormFooterCta} from '@Components/auth-form-footer-cta';
import {useLoginPageAuthSubType, useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import type {FacebookConnectCallback, GoogleSignInCallback, VoidFunction} from '@Components/login-page/login-page.types';
import {SocialMediaIconsContainer} from '@Components/social-media-icons-container';
import {noop} from '@Utils/general.util';
import styles from './login-signup-panel-container.module.scss';
import {Button, Size, Type} from '../button';
import {LoginForm} from '../login-form';
import {PanelHeading} from './components/panel-heading';
import {SignupForm} from '../signup-form';
import {StudentLoginForm} from '../student-login-form';
import {ForgotPasswordForm} from '../forgot-password-form';
import {ConfirmLoginForm} from '../confirm-login-form';
import {Text, TextSize} from '../text';
import useWindowSize from '@/hooks/useWindowSize';
import {useUpdateAuthFormTypes} from '@/hooks/login-signup/useUpdateAuthFormTypes';

interface LoginSignupPanelContainerProps {
  isPanelInModal: boolean;
  panelType: AUTH_TYPE;
  textClasses?: string;
  onSubmitSuccess?: VoidFunction;
  onPanelUpdate?: VoidFunction;
  onGoogleSignInDone?: GoogleSignInCallback;
  onAppleSignInDone?(userId: string): void;
  onFacebookConnect?: FacebookConnectCallback;
  showDivider?: boolean;
  loginOptionsMode?: LOGIN_OPTIONS;
}

export function LoginSignupPanelContainer({
  isPanelInModal,
  panelType,
  textClasses = '',
  onSubmitSuccess = noop,
  onPanelUpdate = noop,
  onGoogleSignInDone = noop,
  onAppleSignInDone = noop,
  onFacebookConnect = noop,
  showDivider = false,
  loginOptionsMode = LOGIN_OPTIONS.DEFAULT,
}: LoginSignupPanelContainerProps): ReactElement {
  const windowScreenSize = useWindowSize();
  const type = useLoginPageAuthType();
  const subType = useLoginPageAuthSubType();
  const {updateAuthTypesAndClearErrors} = useUpdateAuthFormTypes();

  const updateAuthFormType = (newAuthType: AUTH_TYPE, newAuthSubType?: AUTH_SUB_TYPE): void => {
    updateAuthTypesAndClearErrors(newAuthType, newAuthSubType, onPanelUpdate);
  };

  const switchToSignupPanelWithForm = (): void => {
    updateAuthFormType(AUTH_TYPE.SIGNUP, AUTH_SUB_TYPE.SIGNUP_WITH_FORM);
  };

  const switchToSignupPanelWithoutForm = (): void => {
    updateAuthFormType(AUTH_TYPE.SIGNUP, AUTH_SUB_TYPE.SIGNUP_WITHOUT_FORM);
  };

  const switchToLoginPanelWithForm = (): void => {
    updateAuthFormType(AUTH_TYPE.LOGIN, AUTH_SUB_TYPE.LOGIN_WITH_FORM);
  };

  const switchToLoginPanelWithoutForm = (): void => {
    updateAuthFormType(AUTH_TYPE.LOGIN, AUTH_SUB_TYPE.LOGIN_WITHOUT_FORM);
  };

  const switchToStudentLoginPanel = (): void => {
    updateAuthFormType(AUTH_TYPE.STUDENT_LOGIN);
  };

  const switchToForgotPasswordPanel = (): void => {
    updateAuthFormType(AUTH_TYPE.FORGOT_PASSWORD);
  };

  const getPanelHeadingText = (): string => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return shouldFormBeShown() ? window.i18next.t('pmwjs_signup_with_email') : window.i18next.t('pmwjs_user_sign_up_heading');
      case AUTH_TYPE.LOGIN:
        return shouldFormBeShown() ? window.i18next.t('pmwjs_login_with_email') : window.i18next.t('pmwjs_user_log_in');
      default:
        return '';
    }
  };

  const getPanelHeading = (): ReactElement | null => {
    if (isPanelInModal) {
      return null;
    }

    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return (
          <PanelHeading
            showBackOption={shouldBackIconBeShown()}
            changePanelLayout={switchToSignupPanelWithoutForm}
            heading={getPanelHeadingText()}
            linkActionInfo={window.i18next.t('pmwjs_user_login_prompt')}
            linkText={window.i18next.t('pmwjs_user_login_action')}
            switchPanel={switchToLoginPanelWithoutForm}
          />
        );
      case AUTH_TYPE.STUDENT_LOGIN:
        return <PanelHeading showBackOption={true} changePanelLayout={switchToLoginPanelWithoutForm} heading={window.i18next.t('pmwjs_student_login')} />;
      case AUTH_TYPE.CONFIRM_LOGIN:
        return <PanelHeading showBackOption={false} heading={window.i18next.t('pmwjs_just_one_more_step')} />;
      case AUTH_TYPE.FORGOT_PASSWORD:
        return <PanelHeading showBackOption={true} changePanelLayout={switchToLoginPanelWithForm} heading={window.i18next.t('pmwjs_login_forgot_password')} />;
      case AUTH_TYPE.LOGIN:
      default:
        return (
          <PanelHeading
            heading={getPanelHeadingText()}
            linkActionInfo={window.i18next.t('pmwjs_user_sign_up_prompt')}
            switchPanel={switchToSignupPanelWithoutForm}
            linkText={window.i18next.t('pmwjs_user_sign_up')}
            showBackOption={shouldBackIconBeShown()}
            changePanelLayout={switchToLoginPanelWithoutForm}
          />
        );
    }
  };

  const getCtaButtonSection = (): ReactElement | null => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return (
          <div className="spacing-m-b-6">
            {!isPanelInModal ? <LineDivider dividerClassName={getLineDividerClass()} /> : null}
            {getCtaButton(window.i18next.t('pmwjs_signup_with_email'))}
          </div>
        );
      case AUTH_TYPE.LOGIN:
        return (
          <div className={getCtaButtonClassname()}>
            {getCtaButton(window.i18next.t('pmwjs_user_continue_with_email'))}
            <LineDivider dividerClassName={getLineDividerClass()} />
          </div>
        );
      default:
        return null;
    }
  };

  const getCtaButtonClassname = (): string => {
    return isPanelInModal || !isMobileScreen(windowScreenSize.windowWidth) ? '' : styles.loginBtn;
  };

  const getCtaButton = (text: string): ReactNode => {
    return (
      <Button
        text={text}
        size={getActionButtonSize()}
        type={Type.PRIMARY}
        icon="icon-envelope"
        customClasses="-fullwidth"
        textClasses={textClasses}
        onClick={isLoginAuthType(type) ? switchToLoginPanelWithForm : switchToSignupPanelWithForm}
      />
    );
  };

  const getActionButtonSize = (): Size => {
    if (isMobileScreen(windowScreenSize.windowWidth)) {
      return Size.SMALL;
    }
    return Size.MEDIUM;
  };

  const getAgreementTextSize = (): TextSize => {
    if (isMobileScreen(windowScreenSize.windowWidth)) {
      return TextSize.XXSMALL;
    }
    return TextSize.XSMALL;
  };

  const getLineDividerClass = (): string => {
    if (isPanelInModal) {
      return styles.modalLineDivider;
    }
    return styles.lineDivider;
  };

  const getPanelFooter = (): ReactElement | null => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return getSignupPanelFooter();
      case AUTH_TYPE.LOGIN:
        return getLoginPanelFooter();
      default:
        return null;
    }
  };

  const getLoginPanelFooter = (): ReactElement => {
    return (
      <>
        {getSignUpActionItem()}
        <AuthFormFooterCta
          text={window.i18next.t('pmwjs_student_login_prompt')}
          btnText={window.i18next.t('pmwjs_use_student_login')}
          icon="icon-graduation"
          onClick={switchToStudentLoginPanel}
        />
      </>
    );
  };

  const getSignupPanelFooter = (): ReactElement => {
    return (
      <>
        <Text
          val={window.i18next.t('pmwjs_signup_terms_of_use', {
            link: window.PMW.util.site_url('info/termsofuse'),
            linkClass: `${styles.link} content-primary hover-transition-all`,
          })}
          className="content-sub-text"
          dangerouslySetInnerHTML
          size={getAgreementTextSize()}
        />
        {isPanelInModal ? <LineDivider dividerClassName={getLineDividerClass()} /> : null}
        {getLoginActionItem()}
      </>
    );
  };

  const getSignUpActionItem = (): ReactNode => {
    if (isPanelInModal) {
      return <AuthFormFooterCta text={window.i18next.t('pmwjs_user_sign_up_prompt')} btnText={window.i18next.t('pmwjs_user_sign_up')} onClick={switchToSignupPanelWithoutForm} />;
    }
    return '';
  };

  const getLoginActionItem = (): ReactNode => {
    if (isPanelInModal) {
      return <AuthFormFooterCta text={window.i18next.t('pmwjs_user_login_prompt')} btnText={window.i18next.t('pmwjs_user_login_action')} onClick={switchToLoginPanelWithoutForm} />;
    }
    return '';
  };

  const getForm = (): ReactNode => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return <SignupForm onAuthFormSubmitSuccess={onSubmitSuccess} showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} />;
      case AUTH_TYPE.STUDENT_LOGIN:
        return (
          <StudentLoginForm
            isPanelScreen
            loginRedirect={switchToLoginPanelWithoutForm}
            signupRedirect={switchToSignupPanelWithoutForm}
            showSmallButton={isMobileScreen(windowScreenSize.windowWidth)}
            onAuthFormSubmitSuccess={onSubmitSuccess}
          />
        );
      case AUTH_TYPE.FORGOT_PASSWORD:
        return <ForgotPasswordForm showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} />;
      case AUTH_TYPE.CONFIRM_LOGIN:
        return <ConfirmLoginForm showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} onPanelUpdate={onPanelUpdate} onAuthFormSubmitSuccess={onSubmitSuccess} />;
      case AUTH_TYPE.LOGIN:
      default:
        return (
          <LoginForm
            switchToForgotPassword={switchToForgotPasswordPanel}
            onAuthFormSubmitSuccess={onSubmitSuccess}
            showSmallButton={isMobileScreen(windowScreenSize.windowWidth)}
            onPanelUpdate={onPanelUpdate}
            loginOptionsMode={loginOptionsMode}
          />
        );
    }
  };

  const getContainerPadding = (): string => {
    if (isPanelInModal) {
      return styles.modalPanelContainer;
    }

    if (isOAuthFlow(loginOptionsMode) && !isNotLoginOrSignupPanel()) {
      return styles.optionsContainer;
    }

    if (shouldFormBeShown()) {
      return styles.formContainer;
    }
    return styles.optionsContainer;
  };

  const hideThirdPartyButtons = (): boolean => {
    return isPanelInModal && !areThirdPartyButtonsNeededForType(panelType);
  };

  const getThirdPartyButtons = (): ReactNode => {
    return (
      <ThirdPartyLoginButtons
        onFacebookConnect={onFacebookConnect}
        onGoogleSignInDone={onGoogleSignInDone}
        onAppleSignInDone={onAppleSignInDone}
        hideThirdPartyButtons={hideThirdPartyButtons()}
        googleCustomClassname={isPanelInModal ? '' : 'spacing-m-b-3'}
      />
    );
  };

  const shouldBackIconBeShown = (): boolean => {
    if (isNotLoginOrSignupPanel()) {
      return true;
    }

    if (isOAuthFlow(loginOptionsMode)) {
      return false;
    }

    return authSubTypeHasForm();
  };

  const shouldFormBeShown = (): boolean => {
    if (isNotLoginOrSignupPanel() || isOAuthFlow(loginOptionsMode)) {
      return true;
    }

    return authSubTypeHasForm();
  };

  const isNotLoginOrSignupPanel = (): boolean => {
    return panelType === AUTH_TYPE.STUDENT_LOGIN || panelType === AUTH_TYPE.FORGOT_PASSWORD || panelType === AUTH_TYPE.CONFIRM_LOGIN;
  };

  const authSubTypeHasForm = (): boolean => {
    return subType === AUTH_SUB_TYPE.LOGIN_WITH_FORM || subType === AUTH_SUB_TYPE.SIGNUP_WITH_FORM;
  };

  return (
    <div className={styles.container}>
      <div className={getContainerPadding()}>
        <LoginSignupPanel showForm={shouldFormBeShown()}>
          {getPanelHeading()}
          <div className={shouldFormBeShown() ? '_hidden' : ''}>
            {getThirdPartyButtons()}
            {showDivider ? <LineDivider className={styles.panelDividerContainer} dividerClassName={styles.panelLineDivider} includeTextInDivider /> : null}
            {getCtaButtonSection()}
            {getPanelFooter()}
          </div>

          {shouldFormBeShown() ? <div className={styles.panelFormContainer}>{getForm()}</div> : null}
          {isLoginOrSignupAuthType(type) ? (
            <div className={`${styles.socialMediaIconsContainer}`}>
              <SocialMediaIconsContainer />
            </div>
          ) : null}
        </LoginSignupPanel>
      </div>
    </div>
  );
}
