import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import styles from './login-signup-panel.module.scss';

interface LoginPanelProps {
  showForm: boolean;
  children: ReactNode;
}

export function LoginSignupPanel({showForm, ...props}: LoginPanelProps): ReactElement {
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    setAnimation(styles.fade);
  }, [showForm]);

  return (
    <div
      className={`${styles.panelContainer} ${animation}`}
      onAnimationEnd={() => {
        setAnimation('');
      }}
    >
      {props.children}
    </div>
  );
}
