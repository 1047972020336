import React, {useEffect, useState} from "react";
import {initRecaptcha} from "@Libraries/recaptcha-library";

export const useIsRecaptchaLoaded = () => {

    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

    const updateStateOnLoad = () => {
        let textarea = document.getElementById("g-recaptcha-response-100000");
        if (textarea) {
            textarea.setAttribute("aria-hidden", "true");
            textarea.setAttribute("aria-label", "do not use");
        }

        setIsRecaptchaLoaded(true);
    }

    useEffect(() => {
        if (!isRecaptchaLoaded) {
            initRecaptcha(updateStateOnLoad);
        }
    }, []);

    return isRecaptchaLoaded;
}