import type {ReactNode} from 'react';
import React from 'react';
import styles from './student-login-info-text.module.scss';
import type { TextSize} from '../../../text';
import {Text} from '../../../text';

/**
 @author Talha Ashar <talha@250mils.com>
 */

interface StudentLoginInfoTextProps {
  textSize: TextSize;
  isPanelScreen?: boolean;
}

function StudentLoginInfoText({textSize, isPanelScreen = false}: StudentLoginInfoTextProps) {
  const getTeacherLoginMessage = (): string => {
    let message = window.i18next.t('pmwjs_teacher_login_message', {linkClass: `${styles.link} hover-transition-all`});
    if (isPanelScreen) {
      message = window.i18next.t('pmwjs_teacher_login_message_mobile', {linkClass: `${styles.link} hover-transition-all`});
    }
    return `${message} `;
  };

  const continuedMessageForLoginPage = (): ReactNode => {
    return (
      <>
        <br />
        <br />
        <Text
          val={window.i18next.t('pmwjs_student_login_classroom_info', {link: window.PMW.util.site_url('info/termsofuse'), linkClass: `${styles.link} hover-transition-all`})}
          dangerouslySetInnerHTML
          size={textSize}
        />
      </>
    );
  };

  const getRemainingInfo = (): ReactNode => {
    if (isPanelScreen) {
      return;
    }
    return continuedMessageForLoginPage();
  };

  return (
    <>
      <Text val={getTeacherLoginMessage()} size={textSize} dangerouslySetInnerHTML className={styles.text} />
      {getRemainingInfo()}
    </>
  );
}

export default React.memo(StudentLoginInfoText);
