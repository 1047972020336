import React from 'react';
import {Checkbox, CHECKBOX_TYPE} from '@Components/checkbox';
import {Text, TextSize} from '@Components/text';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import styles from './email-update-notifications.module.scss';

interface EmailUpdateNotificationsProps {
  onCheckboxClick: VoidFunction;
  emailUpdates: boolean;
}

function EmailUpdateNotifications({onCheckboxClick, emailUpdates}: EmailUpdateNotificationsProps) {
  return (
    <div className={`flex-row-align-center ${styles.notifyContainer}`}>
      <div className="spacing-m-r-2 spacing-m-l-1">
        <Checkbox isChecked={emailUpdates} onClick={onCheckboxClick} type={CHECKBOX_TYPE.DARKER} />
      </div>

      <div onClick={onCheckboxClick}>
        <Text val={window.i18next.t('pmwjs_notify_promotions')} className={styles.notifyText} size={TextSize.XSMALL} />
      </div>
    </div>
  );
}

export default React.memo(EmailUpdateNotifications);
