import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {enableAuthButtonsAccess} from '@Components/login-page/login-page-reducer';
import {setRecaptchaSelector} from '@Libraries/login-signup-library';
import {useIsRecaptchaLoaded} from '@/hooks/recaptcha/useIsRecaptchaLoaded';

export const useOnRecaptchaRender = () => {
  const isRecaptchaLoaded = useIsRecaptchaLoaded();
  const dispatch = useDispatch();
  const grecaptchaBadge: HTMLElement | null = document.querySelector('.grecaptcha-badge');

  const showGrecaptchaBadge = (): void => {
    if (grecaptchaBadge) {
      grecaptchaBadge.style.visibility = 'visible';
      grecaptchaBadge.style.zIndex = '10000';
    }
  };

  const hideGrecaptchaBadge = (): void => {
    if (grecaptchaBadge) {
      grecaptchaBadge.style.visibility = 'hidden';
    }
  };

  useEffect(() => {
    if (isRecaptchaLoaded) {
      window.PMW.grecaptchaReady = true;
      dispatch(enableAuthButtonsAccess());
      showGrecaptchaBadge();
    }
  }, [isRecaptchaLoaded]);

  return {showGrecaptchaBadge, hideGrecaptchaBadge};
};
